export default class Typewriter {
	private _class: string
	private _elem: HTMLElement

	constructor(cssClass: string = 'typewriter') {
		this._class = cssClass
	}

	run() {
		this._elem = document.querySelector(this._class) as HTMLElement
		const chars: string[] = this.splitStringToChars(this._elem.innerHTML)
		this._elem.innerHTML = ''
		window.setTimeout(() => {
			this.typeWrite(chars)
		}, 1000)
	}

	splitStringToChars(stringToSplit: string): string[] {
		return stringToSplit.split('');
	}

	typeWrite(chars: string[]): void {
		const ms =
			30 + Math.floor(Math.random() * 220)
		window.setTimeout(() => {
			this.addChar(chars[0])
			chars.shift()

			if (chars.length > 0) {
				requestAnimationFrame(() => {
					this.typeWrite(chars)
				})
			}
		}, ms)
	}

	addChar(char: string): void {
		this._elem.append(char)
	}
}